var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":_vm.id,"hide-footer":true,"centered":"","hide-header":false,"no-close-on-backdrop":_vm.isNoClose,"title":_vm.title},on:{"hidden":_vm.close}},[_c('div',{staticClass:"form-input"},[_c('validation-observer',{ref:"rule"},[_c('div',{staticClass:"addwork__item"},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInputCode"}},[_vm._v("Mã vị trí công việc "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: 'Mã vị trí công việc là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInputCode","state":errors.length > 0 ? false:null,"value":""},model:{value:( _vm.dataValue.code ),callback:function ($$v) {_vm.$set(_vm.dataValue, "code", $$v)},expression:" dataValue.code "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"addwork__item"},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInputName"}},[_vm._v("Tên vị trí công việc "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: 'Tên vị trí công việc là bắt buộc',}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInputName","state":errors.length > 0 ? false:null,"value":""},model:{value:( _vm.dataValue.name ),callback:function ($$v) {_vm.$set(_vm.dataValue, "name", $$v)},expression:" dataValue.name "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Nhóm công việc "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: 'Nhóm công việc là bắt buộc',}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (title) { return title.name; },"label":"title","options":_vm.allPosition,"placeholder":'Lựa chọn nhóm công việc'},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataValue.positionGroup),callback:function ($$v) {_vm.$set(_vm.dataValue, "positionGroup", $$v)},expression:"dataValue.positionGroup"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Cơ cấu tổ chức "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: 'Cơ cấu tổ chức là bắt buộc',}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('organization-select',{staticClass:"border-organization",attrs:{"disableProp":_vm.disableProp},model:{value:(_vm.dataValue.organizationalStructureId),callback:function ($$v) {_vm.$set(_vm.dataValue, "organizationalStructureId", $$v)},expression:"dataValue.organizationalStructureId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"addwork__item"},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInputName"}},[_vm._v("Yêu cầu công việc ")]),_c('b-form-input',{attrs:{"id":"basicInputName","value":""},model:{value:( _vm.dataValue.requirement ),callback:function ($$v) {_vm.$set(_vm.dataValue, "requirement", $$v)},expression:" dataValue.requirement "}})],1)],1),_c('div',[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Mô tả công việc")]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"","rows":"3","no-resize":""},model:{value:(_vm.dataValue.description),callback:function ($$v) {_vm.$set(_vm.dataValue, "description", $$v)},expression:"dataValue.description"}})],1)],1)],1),_c('div',{staticClass:"action"},[(!_vm.isNotification)?_c('b-button',{attrs:{"variant":"outline-primary mr-1 "},on:{"click":_vm.cancel}},[_vm._v(" Hủy bỏ ")]):_vm._e(),_c('b-button',{staticClass:"btn-left",attrs:{"variant":"primary"},on:{"click":_vm.accept}},[_vm._v(" Lưu lại ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div>
      <div class="page-container pb-0 mb-2">
        <filter-component
          @filter="handleFilter($event)"
        />
      </div>
      <div class="page-container-table">
        <button-all-header
          :contentBtnAdd="'Thêm vị trí công việc'"
          :arrayExcel="arrayExcel"
          :showBtnMultiDelete="showBtnMultiDelete"
          @clickDelete="deleteMore"
          @clickDowloadSample="downExcelSample"
          @clickExportExcel="downExcelAll"
          @importFile="fileChange($event)"
          @clickAdd="showAdd"
          @search="search($event)"
        />
        <div>
          <div>
            <vue-good-table
              :columns="columns"
              :rows="dataJob || []"
              :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
              style-class="vgt-table"
              :sort-options="{
                enabled: false,
                initialSortBy: { field: 'code', type: 'asc' },
                initialSortBy: { field: 'name', type: 'asc' },
              }"
              @on-selected-rows-change="selectRowTable"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field == 'name'">
                  <span>{{ props.row.name }}</span>
                </span>
                <span v-if="props.column.field == 'code'">
                  {{ props.row.code }}
                </span>
                <span v-if="props.column.field == 'description'">
                  <span>{{ props.row.description }}</span>
                </span>
                <span v-if="props.column.field == 'organizationalStructure'">
                  <span>{{ props.row.organizationalStructure }}</span>
                </span>
                <span v-if="props.column.field == 'cn'">
                  <feather-icon
                    v-b-tooltip.hover.top="'Chỉnh sửa'"
                    icon="EditIcon"
                    size="18"
                    class="table__chucnang__icon"
                    @click="edit(props.row.id)"
                  />

                  <feather-icon
                    v-b-tooltip.hover.top="'Xóa'"
                    icon="Trash2Icon"
                    size="18"
                    class="table__chucnang__icon text-body"
                    style="margin-left: 14px"
                    @click.stop="handleClickDeleteButton(props.row.id)"
                  />
                </span>
              </template>
              />
            </vue-good-table>
          </div>
        </div>
        <div class="table__bottom pt-5">
          <my-pagination
            :totalItems="totalRows"
            :currentPage="urlQuery.pageNumber"
            @pageClick="handlePageClick"
          />
        </div>
      </div>
    </div>
    <modal-delete
      :id="'delete-modal'"
      :comboBoxDel="allPosition"
      :name="'Vị trí công việc'"
      @accept="handelDelete"
    />
    <Add
      id="CreatePosition"
      :dataDetail="dataDetail"
      :idDetail="idDetail"
      :title="modeType ==='add' ? 'Thêm vị trí công việc': 'Chỉnh sửa vị trí công việc'"
      @callAPI="create"
    />
  </div>
</template>

<script>
import VSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import Search from '@/components/search/Search.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import ModalDelete from './components/ModalDelete.vue'
import FilterComponent from './components/FilterComponent.vue'
import Add from './Add.vue'

export default {
  components: {
    BButton,
    VueGoodTable,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    VSelect,
    ValidationProvider,
    ValidationObserver,
    MyPagination,
    Search,
    ButtonAllHeader,
    ModalDelete,
    Add,
    VBTooltip,
    FilterComponent,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      customAlertDel: {
        required: 'Vị trí công việc thay thế là bắt buộc',
      },
      showBtnMultiDelete: false,
      totalRows: 5,
      text: '',
      dataJob: '',
      deleteModel: {
        id: '',
        positionId: '',
      },
      allPosition: '',
      file: '',
      selectData: [],
      currentPage: 5,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Mã vị trí công việc',
          field: 'code',
          tdClass: 'style-code',
          width: '200px',
        },
        {
          label: 'Vị trí công việc',
          field: 'name',
        },
        {
          label: 'Mô tả chi tiết',
          field: 'description',
        },
        {
          label: 'CƠ CẤU TỔ CHỨC',
          field: 'organizationalStructure',
        },
        // {
        //   label: 'Nhóm công việc',
        //   field: 'positionGroup',
        // },
        {
          label: 'Chức năng',
          field: 'cn',
          html: true,
          tdClass: 'table__tool',
          thClass: 'table__tool',
          width: '175px',
        },
      ],
      // eslint-disable-next-line quotes
      rows: "",
      arrayExcel: ['code', 'name', 'description', 'requirement', 'positionGroup', 'orStruc'],
      idDetail: '',
      dataDetail: {},
      modeType: 'add',
    }
  },
  computed: {
    ...mapState('positionJob', ['dataPositionJob', 'totalPagesState']),
  },
  async created() {
    await this.fechDataTable()
  },
  methods: {
    ...mapActions('positionJob', [
      'getAllComboBox',
      'deletePositionJob',
      'doFetchDataPositionJobToID',
      'downloadFileAll',
    ]),
    ...mapMutations('positionJob', ['EDITMUTATION', 'VALIDWORKMUTATION', 'VALIDNOWORKMUTATION']),
    selectionChanged(param) {
      this.deleteModel.id = param.selectedRows.map(e => e.id)
    },
    //* **Lấy dữ liệu bảng ***//
    async fechDataTable() {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.GET_LIST, {
        params: this.urlQuery,
      })
      this.dataJob = data.data.pageLists
      this.totalRows = data.data.totalRecord
      this.$hideLoading()
    },
    //* **Phân trang ***//
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fechDataTable()
    },
    //* **Tìm kiêm ***//
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        await this.fechDataTable()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        await this.fechDataTable()
      }
    },
    //* **Tải file mẫu ***//
    async downExcelSample() {
      try {
        axiosApiInstance({
          url: ConstantsApi.DOWN_EXCEL_SAMPLE, // your url
          method: 'GET',
          responseType: 'blob', // important
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'FileMauViTriCongViec.xlsx')
          document.body.appendChild(link)
          link.click()
        })
      } catch (e) {
        this.$root.$bvToast.toast('Tải file bị lỗi!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    //* **Tải file excel có dữ liệu***/
    async downExcelAll() {
      try {
        axiosApiInstance({
          url: ConstantsApi.DOWN_EXCEL_ALL, // your url
          method: 'GET',
          responseType: 'blob', // important
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'ViTriCongViec.xlsx')
          document.body.appendChild(link)
          link.click()
        })
      } catch (e) {
        this.$root.$bvToast.toast('Tải file bị lỗi!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    // ======= ====================   Đọc File Exel import============================//
    fileChange(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    checkRowExcel(a) {
      if (a === null) {
        return a
      }
      return a.toString()
    },
    //= ==========================kiểm tra hợp lệ từ file exel======================//
    async getValidData(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'import-position-job-file' })
    },
    //= ============================================Kiểm tra tính hợp lên khi thêm file Exel================================//
    // eslint-disable-next-line consistent-return
    async getApiExcel(data) {
      this.$showAllPageLoading()
      const apiExcel = await axiosApiInstance.post('/Position/import-position-from-json', data)
      if (apiExcel.status === 200) {
        this.VALIDWORKMUTATION(apiExcel.data.data.filter(e => e.isSuccess === true))
        this.VALIDNOWORKMUTATION(apiExcel.data.data.filter(e => e.isSuccess === false))
      }
      this.$hideAllPageLoading()
    },

    //= ============================ Chỉnh sửa vị trí công viêc ======================//
    async edit(id) {
      this.modeType = 'edit'
      this.idDetail = id
      const { data } = await axiosApiInstance.get(`/Position/positions/${this.idDetail}`)
      this.dataDetail = data
      this.$bvModal.show('CreatePosition')
    },
    // =========================================click vào nút xóa =================================
    selectRowTable(val) {
      this.deleteModel.id = val.selectedRows.map(e => e.id)
      this.showBtnMultiDelete = this.deleteModel.id.length > 0
    },
    async handleClickDeleteButton(id) {
      const comboBox = await this.getAllComboBox([id])
      this.allPosition = comboBox.data.data
      this.deleteModel.id = [id]
      this.$bvModal.show('delete-modal')
    },
    async deleteMore() {
      const comboBox = await this.getAllComboBox(this.deleteModel.id)
      this.allPosition = comboBox.data.data
      this.$bvModal.show('delete-modal')
    },
    //= ========================== kiểm tra validate và xóa kiều người dùng ========================******
    async handelDelete(e) {
      this.deleteModel.positionId = e
      const response = await this.deletePositionJob(this.deleteModel)
      if (response.status === 200) {
        await this.fechDataTable()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo', variant: 'success', toaster: this.$toastPosition, solid: true,
        })
      }
    },

    async handleFilter(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.organizationStructureId = val
        await this.fechDataTable()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.organizationStructureId = ''
        await this.fechDataTable()
      }
    },

    // reset dữ liệu
    resetDeleteModel() {
      this.deleteModel.positionId = null
    },
    async create() {
      await this.fechDataTable()
    },
    showToast(variant, nameAlert) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Thông báo',
          icon: 'BellIcon',
          text: nameAlert,
          variant,
        },
      })
    },

    showAdd() {
      this.modeType = 'add'
      this.$bvModal.show('CreatePosition')
    },
  },
}
</script>
<style lang="scss">
.style-code {
  span {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
    padding: 1px 9px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #227ff4;
  }
}
.table__tool{
  width: 150px;
  text-align: center;
}

// @media (max-width: 425px) {
//   .search-input {
//     width: unset !important;
//     margin: 0px 1.5rem 1rem 1.5rem;
//   }
// }

</style>

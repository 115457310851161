<template>
  <div id="filter-custom">
    <h3
      class="header"
    >
      Bộ lọc
    </h3>
    <b-row>
      <!-- Huyện -->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group>
          <label
            for="basicInput"
          >Cơ cấu tổ chức <span class="required"></span></label>
          <organization-select
            v-model="organizationStructureId"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import OrganizationSelect from '@/views/management-user/users/pages/components/OrganizationSelect.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VSelect,
    OrganizationSelect,
  },
  data() {
    return {
      organizationStructureId: '',
    }
  },
  watch: {
    organizationStructureId(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.$emit('filter', this.organizationStructureId)
      }
    },
  },

}
</script>

<style lang="scss">
#filter-custom{
  .header{
    font-size: 18px;
    color: #181F28;
  }
}
</style>

<template>
  <div>
    <b-modal
      :id="id"
      centered
      :hide-footer="true"
      :hide-header="true"
      :no-close-on-backdrop="isNoClose"
    >
      <div class="confirm-modal-delete">
        <div class="text-warning">
          <feather-icon :icon="icon" />
        </div>
        <div class="title">
          Bạn chắc chắn xóa
        </div>
        <b-col>
          <validation-observer ref="rule">
            <b-form class="content-form">
              <b-form-group label-for="basicInput">
                <label for="InputHelp">{{ `Chuyển đến ${name} khác (bắt buộc) ` }}<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="userTypeId"
                  rules="required"
                  :custom-messages="customAlertDel"
                >
                  <v-select
                    v-model="SelectId"
                    :reduce="(name) => name.id"
                    label="name"
                    :placeholder="`Lựa chọn ${name} thay thế`"
                    :options="comboBoxDel || []"
                    :class="{'is-invalid': !!errors.length}"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      {{ option.name }}
                    </template>
                    <template #selected-option-container="{ option }">
                      <div class="vs__selected">
                        {{ option.name }}
                      </div>
                    </template>
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-col>

        <div class="action">
          <b-button
            variant="primary"
            class="mr-1 btn-left"
            @click="accept"
          >
            <!-- {{ $t("common.ok-title") }} -->
            Đồng ý
          </b-button>
          <b-button
            v-if="!isNotification"
            variant="outline-danger"
            @click="cancel"
          >
            <!-- {{ $t("common.cancel-title") }} -->
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCardText, BButton, BFormGroup, BForm, BCol,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BCardText,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BForm,
    BCol,
  },
  props: {
    name: {
      type: String,
      default: 'name',
    },
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    id: {
      type: String,
      default: 'confirm-modal',
    },
    // nếu là thông báo không có nút cancel
    isNotification: {
      type: Boolean,
      default: false,
    },
    isNoClose: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    comboBoxDel: {
      default: '',
    },
  },
  data() {
    return {
      required,
      SelectId: '',
      customAlertDel: {
        required: `Lựa chọn ${this.name} là bắt buộc`,
      },
    }
  },
  methods: {
    accept() {
      this.$refs.rule.validate().then(async success => {
        if (success) {
          this.$bvModal.hide(this.id)
          this.$emit('accept', this.SelectId)
          this.SelectId = ''
        }
      })
    },
    cancel() {
      this.$bvModal.hide(this.id)
      this.$emit('cancel')
      this.SelectId = ''
    },
  },
}
</script>
<style lang="scss">
.confirm-modal-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  .text-warning {
    svg {
      width: 80px;
      height: 80px;
    }
  }
  .title {
    margin-top: 10px;
    font-weight: 500;
    font-size: 21px;
  }
  .action {
    margin-top: 25px;
  }
  .description {
    font-size: 16px;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .confirm-modal-delete {
    padding: 20px;
  }
}
@media (max-width: 375px) {
  .confirm-modal-delete {
    .action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .btn-left {
        margin-right: 0px !important;
      }
      .btn {
        margin-top: 10px;
      }
    }
  }
}
</style>
